import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IEtapaProcessoVendas,
  IEtapaProcessoVendasPerfilUsuario,
  IEtapaProcessoVendasProximaEtapa,
  IEtapaProcessoVendasUsuario,
  IProcessoVendas,
  IProcessoVendasPerfilUsuario,
  IProcessoVendasUsuario,
} from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';

/**
 * Serviço de Processos de Vendas
 * Fornece todas regras de negócios e lógicas relacionado a processos de vendas.
 */

class ServicoProcessoVendas implements IServicoBase<IProcessoVendas> {
    endPoint = 'processos-vendas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IProcessoVendas): string {
      return objeto.nome;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterprocessoVendasConsultaRapida(codigoprocessoVendas: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoprocessoVendas.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaprocessoVendass = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaprocessoVendass)) {
        return listaprocessoVendass[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(processoVendas: IProcessoVendas): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, processoVendas);
      return result.data;
    }

    public async alterar(processoVendas: IProcessoVendas): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${processoVendas.codigo}`, processoVendas);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IProcessoVendas> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterProcessoCompletoCache(codigo: number, empresa: number): Promise<IProcessoVendas> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}?cache=true`);
      return result.data;
    }

    public async obterPerfisUsuariosProcesso(codigo: number, empresa: number): Promise<IProcessoVendasPerfilUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/perfis-usuarios-processo/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterUsuariosProcesso(codigo: number, empresa: number): Promise<IProcessoVendasUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/usuarios-processo/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterProximasEtapas(codigo: number, empresa: number): Promise<IEtapaProcessoVendasProximaEtapa[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/proximas-etapas/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterEtapas(processo: number, empresa: number): Promise<IEtapaProcessoVendas[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/etapas/processo/${processo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterPerfisUsuariosEtapa(codigo: number, empresa: number): Promise<IEtapaProcessoVendasPerfilUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/etapas/${codigo}/perfis-usuarios/empresa/${empresa}`);
      return result.data;
    }

    public async obterUsuariosEtapa(codigo: number, empresa: number): Promise<IEtapaProcessoVendasUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/etapas/${codigo}/usuarios/empresa/${empresa}`);
      return result.data;
    }

    public async validarVendasAndamento(processo: number, empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/validar-vendas-andamento/${processo}/empresa/${empresa}`);
      return result.data;
    }

    public async validarVendasEtapa(etapa: number, empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/validar-vendas-etapa/etapa/${etapa}/empresa/${empresa}`);
      return result.data;
    }

    public validarEtapaReferenciada(codigo: string, etapasDisponiveis: IEtapaProcessoVendas[]): boolean {
      let referenciado = false;
      etapasDisponiveis.forEach((e) => {
        const indexEtapa = e.proximasEtapas.findIndex((p) => String(p.codigoProximaEtapa) === codigo || p.codigoProximaEtapaProvisorio === codigo);
        if (indexEtapa > -1) {
          referenciado = true;
        }
      });
      return referenciado;
    }

    public validarPossuiProximasEtapas(codigo: string, etapasDisponiveis: IEtapaProcessoVendas[]): boolean {
      const indexEtapa = etapasDisponiveis.findIndex((e) => String(e.codigo) === codigo || e.codigoProvisorio === codigo);
      let proximasEtapas = false;

      etapasDisponiveis[indexEtapa].proximasEtapas.forEach((e) => {
        const index = etapasDisponiveis.findIndex((ed) => ed.codigo === e.codigoProximaEtapa);

        if (etapasDisponiveis[index].statusVenda !== EStatusVenda.Cancelada) {
          proximasEtapas = true;
        }
      });

      return proximasEtapas;
    }

    public obterEtapaCancelamento(codigo: string, etapasDisponiveis: IEtapaProcessoVendas[]): IEtapaProcessoVendas {
      const indexEtapa = etapasDisponiveis.findIndex((e) => String(e.codigo) === codigo || e.codigoProvisorio === codigo);
      let etapaCancelamento = {} as IEtapaProcessoVendas;

      etapasDisponiveis[indexEtapa].proximasEtapas.forEach((e) => {
        const index = etapasDisponiveis.findIndex((ed) => ed.codigo === e.codigoProximaEtapa);

        if (etapasDisponiveis[index].statusVenda === EStatusVenda.Cancelada) {
          etapaCancelamento = etapasDisponiveis[index];
        }
      });
      return etapaCancelamento;
    }

    private obterCodigosProximasEtapasFilhos(codigo: number, codigos: number[], etapasDisponiveis: IEtapaProcessoVendas[]) {
      const indexEtapa = etapasDisponiveis.findIndex((e) => e.codigo === codigo);

      etapasDisponiveis[indexEtapa].proximasEtapas.forEach((pe) => {
        codigos.push(pe.codigoProximaEtapa);
        this.obterCodigosProximasEtapasFilhos(pe.codigoProximaEtapa, codigos, etapasDisponiveis);
      });
    }

    public obterCodigosProximasEtapas(codigo: number, etapasDisponiveis: IEtapaProcessoVendas[]): number[] {
      const codigos = [] as number[];
      const indexEtapa = etapasDisponiveis.findIndex((e) => e.codigo === codigo);

      etapasDisponiveis[indexEtapa].proximasEtapas.forEach((pe) => {
        codigos.push(pe.codigoProximaEtapa);
        this.obterCodigosProximasEtapasFilhos(pe.codigoProximaEtapa, codigos, etapasDisponiveis);
      });
      return codigos;
    }

    private obterCodigosEtapasAnterioresFilhos(codigo: number, codigos: number[], etapasDisponiveis: IEtapaProcessoVendas[]) {
      etapasDisponiveis.forEach((e) => {
        e.proximasEtapas.forEach((pe) => {
          if (pe.codigoProximaEtapa === codigo) {
            codigos.push(e.codigo);
            this.obterCodigosEtapasAnterioresFilhos(e.codigo, codigos, etapasDisponiveis);
          }
        });
      });
    }

    public obterCodigosEtapasAnteriores(codigo: number, etapasDisponiveis: IEtapaProcessoVendas[]): number[] {
      const codigos = [] as number[];

      etapasDisponiveis.forEach((e) => {
        e.proximasEtapas.forEach((pe) => {
          if (pe.codigoProximaEtapa === codigo) {
            codigos.push(e.codigo);
            this.obterCodigosEtapasAnterioresFilhos(e.codigo, codigos, etapasDisponiveis);
          }
        });
      });
      return codigos;
    }

    public obterCodigoEtapa(etapa: IEtapaProcessoVendas): string {
      let codigo = String(etapa.codigo);
      if (!UtilitarioGeral.validaCodigo(etapa.codigo)) {
        codigo = etapa.codigoProvisorio;
      }
      return codigo;
    }
}
export default ServicoProcessoVendas;
