
import {
  defineComponent, onBeforeMount, reactive, watch, ref, provide,
} from 'vue';
import { useRoute } from 'vue-router';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ICardKanban, ICardKanbanDetalhe, IColunaKanban } from '@/core/models/IKanban';
import Kanban from '@/core/components/Kanban/Kanban.vue';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IEtapaProcessoVendas, IProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IDTOVenda } from '@/models/DTO/Vendas/IDTOVenda';
import VendaModal from './VendaModal.vue';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import ServicoProcessoVendas from '@/servicos/Cadastros/Vendas/ServicoProcessoVendas';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';
import VendasListagem from '@/components/Vendas/Vendas/VendasListagem.vue';
import VendasCancelamentoModal from './VendasCancelamentoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import { IParametrosConsultaVenda } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaVenda';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import CartaCorrecaoModal from '@/components/Fiscal/Faturamentos/CartaCorrecaoModal.vue';
import VendasUnificarServicosModal from './VendasUnificarServicosModal.vue';

export default defineComponent({
  name: 'VendasIndex',
  components: {
    Icone,
    Kanban,
    VendaModal,
    VendasListagem,
    VendasCancelamentoModal,
    SelecionarFiltroPeriodoMovimentoComercial,
    SelecionarDataInicialFinal,
    TituloPadrao,
    Card,
    BuscaGeral,
    PreviewPdf,
    CartaCorrecaoModal,
    VendasUnificarServicosModal,
  },
  setup(props) {
    const servicoVendas = new ServicoVendas();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const servicoProcessoVendas = new ServicoProcessoVendas();
    servicoProcessoVendas.requisicaoSistema();
    const route = useRoute();
    const {
      telaBase, preencherEmpresasDisponiveis, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const processoVendas = ref<IProcessoVendas>({} as IProcessoVendas);
    const etapaLancamento = ref<IEtapaProcessoVendas>({} as IEtapaProcessoVendas);
    const dadosEmpresa = ref<IDTODadosEmpresa>({} as IDTODadosEmpresa);
    const permissaoServico = ref<boolean>({} as boolean);
    provide('VENDAS_EMPRESA', dadosEmpresa);
    provide('VENDAS_PROCESSO_VENDAS', processoVendas);
    provide('VENDAS_ETAPA_LANCAMENTO', etapaLancamento);
    provide('VENDAS_PERMISSAO_SERVICO', permissaoServico);

    let debounce = 0;
    const state = reactive({
      identificadorRecurso: 'VENDAS',
      tituloRecurso: '',
      dataAtual: '',
      filtroPeriodo: 4,
      dataInicialSelecionada: '',
      dataFinalSelecionada: '',
      colunas: [] as IColunaKanban[],
      filtros: [] as IFiltroGenerico[],
      selecionados: [] as number[],
      buscaRapida: '',
      codigoVendaSelecionada: {} as number,
      vendaSelecionada: {} as IDTOVenda,
      exibirAlteracaoEtapa: false,
      exibirUnificacaoServicos: false,
      carregandoTela: false,
      paginacao: {} as IPagination,
      cardSelecionado: '',
      vendas: [] as IDTOVenda[],
      buscaAvancada: {} as IBuscaAvancada,
      exibirCancelamento: false,
      exibirLancamento: false,
      exibirCartaCorrecao: false,
      vendaLancamento: 0,
      qtdSelecionados: 0,
      qtdSelecionadosConcluir: 0,
      qtdSelecionadosRetomar: 0,
      qtdSelecionadosExcluir: 0,
      visualizarKanban: true,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function atualizarQtdSelecionados() {
      state.qtdSelecionados = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.colunas.forEach((c) => {
        const possuiProxima = servicoProcessoVendas.validarPossuiProximasEtapas(c.identificador, processoVendas.value.etapas);
        const possuiAnterior = servicoProcessoVendas.validarEtapaReferenciada(c.identificador, processoVendas.value.etapas);
        const indexEtapa = processoVendas.value.etapas.findIndex((e) => String(e.codigo) === c.identificador);
        if (c.qtdSelecionados !== undefined) {
          state.qtdSelecionados += c.qtdSelecionados;
          if (possuiProxima) {
            state.qtdSelecionadosConcluir += c.qtdSelecionados;
          }
          if (possuiAnterior && processoVendas.value.etapas[indexEtapa].statusVenda !== EStatusVenda.Cancelada && processoVendas.value.etapas[indexEtapa].statusVenda !== EStatusVenda.Finalizada) {
            state.qtdSelecionadosRetomar += c.qtdSelecionados;
          }
        }
      });
    }

    function atualizarTodosSelecionados() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
      });
      state.vendas.forEach((v) => {
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(v.codigoEtapaProcessoVendas));
        if (indexColuna > -1) {
          const indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(v.codigo));
          const indexSelecionados = state.selecionados.findIndex((s) => s === v.codigo);
          if (indexSelecionados >= 0) {
            state.colunas[indexColuna].qtdSelecionados += 1;
            state.colunas[indexColuna].cards[indexCard].selecionado = true;
          } else {
            state.colunas[indexColuna].cards[indexCard].selecionado = false;
          }
        }
      });
      atualizarQtdSelecionados();
      state.selecionados.forEach((s) => {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === s);
        if (servicoVendas.verificaSePodeExcluir(state.vendas[indexVenda].status)) {
          state.qtdSelecionadosExcluir += 1;
        }
      });
    }

    function atualizarSelecionados(identificador: string) {
      atualizarQtdSelecionados();
      const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
      if (indexSelecionados < 0) {
        state.selecionados.push(Number(identificador));
      } else {
        state.selecionados.splice(indexSelecionados, 1);
      }
      state.selecionados.forEach((s) => {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === s);
        if (servicoVendas.verificaSePodeExcluir(state.vendas[indexVenda].status)) {
          state.qtdSelecionadosExcluir += 1;
        }
      });
    }

    function preencherCards() {
      state.selecionados = [] as number[];
      state.colunas.forEach((c, index) => {
        state.colunas[index].cards = [] as ICardKanban[];
      });

      state.vendas.forEach((v) => {
        const card = {} as ICardKanban;
        const indexSelecionados = state.selecionados.findIndex((s) => s === v.codigo);
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(v.codigoEtapaProcessoVendas));
        if (indexColuna > -1) {
          card.identificadorColuna = String(v.codigoEtapaProcessoVendas);
          card.identificador = String(v.codigo);
          card.titulo = `Nº: ${v.numero}`;
          card.valor = v.totalVenda + v.totalServicos;
          card.ordem = v.ordem;
          card.selecionado = indexSelecionados >= 0;
          card.detalhes = [] as ICardKanbanDetalhe[];

          let indexDetalhe = 0;

          if (v.numeroNotaFiscal > 0) {
            card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
            card.detalhes[indexDetalhe].titulo = `${servicoNotaFiscal.nomeAmigavelModelo(v.modeloNotaFiscal)}: `;
            card.detalhes[indexDetalhe].detalhe = v.numeroNotaFiscal.toString();
            indexDetalhe += 1;
          }
          card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
          card.detalhes[indexDetalhe].titulo = 'Data de Criação: ';
          card.detalhes[indexDetalhe].detalhe = UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(v.dataCriacao);
          indexDetalhe += 1;

          card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
          card.detalhes[indexDetalhe].titulo = 'Cliente: ';
          card.detalhes[indexDetalhe].detalhe = v.nomeCliente;
          if (card.detalhes[indexDetalhe].detalhe.length > 29) {
            card.detalhes[indexDetalhe].detalhe = card.detalhes[indexDetalhe].detalhe.substring(0, 29);
          }
          indexDetalhe += 1;

          card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
          card.detalhes[indexDetalhe].titulo = 'Total R$: ';
          card.detalhes[indexDetalhe].detalhe = UtilitarioMascara.mascararValor(v.totalVenda + v.totalServicos, 2);

          state.colunas[indexColuna].cards.push(card);
          if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
        }
      });
      atualizarTodosSelecionados();
    }

    function prencheDatasPeriodo() {
      switch (state.filtroPeriodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.dataInicialSelecionada = state.dataAtual;
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ontem:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          state.dataFinalSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarVendas(ordenacao: IOrdenacao[] = []) {
      state.vendas = [];
      state.filtros = [] as IFiltroGenerico[];
      if (UtilitarioGeral.validaLista(state.buscaAvancada.filtrosAdicionados)) {
        state.buscaAvancada.filtrosAdicionados.forEach((item) => {
          state.filtros.push(item.filtro);
        });
      }

      prencheDatasPeriodo();
      const parametrosConsulta = {} as IParametrosConsultaVenda;
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      parametrosConsulta.empresas = [dadosEmpresa.value.codigoEmpresa];
      parametrosConsulta.ordenacao = Array<string>();

      if (UtilitarioGeral.validaLista(ordenacao) && !state.visualizarKanban) {
        ordenacao.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        parametrosConsulta.ordenacao.push('Status', 'Ordem');
      }

      parametrosConsulta.qtdeRegistrosPagina = 1000;

      telaBase.carregando = true;
      const listaPaginada = await servicoVendas.buscaAvancada(processoVendas.value.codigo, parametrosConsulta, state.filtros);
      state.vendas = listaPaginada.dados;
      telaBase.carregando = false;
      preencherCards();
    }

    async function buscarVendasAposAcoes() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarVendas();
      }, 600);
    }

    async function atualizarOrdemCards(ordenacaoRegistros: IOrdenacaoRegistro[]) {
      await servicoVendas.atualizarOrdenacao(processoVendas.value.codigo, dadosEmpresa.value.codigoEmpresa, ordenacaoRegistros);
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(dadosEmpresa.value.codigoEmpresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      permissaoServico.value = await new ServicoSistema().validarPermissaoModulo(dadosEmpresa.value.codigoEmpresa, 8);
      await buscarVendas();
    }

    function selecionarCard(selecionado: string) {
      state.cardSelecionado = selecionado;
    }

    function incluirVenda() {
      etapaLancamento.value = processoVendas.value.etapas[0];
      state.vendaLancamento = 0;
      state.exibirLancamento = true;
    }

    function editarVenda(codigoVenda: number) {
      const indexVenda = state.vendas.findIndex((e) => e.codigo === codigoVenda);
      const indexEtapa = processoVendas.value.etapas.findIndex((e) => e.codigo === state.vendas[indexVenda].codigoEtapaProcessoVendas);
      etapaLancamento.value = processoVendas.value.etapas[indexEtapa];
      state.vendaLancamento = codigoVenda;
      state.exibirLancamento = true;
    }

    function clickEditar() {
      editarVenda(Number(state.cardSelecionado));
    }

    function cancelarVenda(venda: IDTOVenda, etapaNova: IEtapaProcessoVendas) {
      preencherCards();
      state.vendaSelecionada = venda;
      etapaLancamento.value = etapaNova;
      state.exibirCancelamento = true;
    }

    async function alterarEtapaVenda(indexVenda: number, etapaAnterior: IEtapaProcessoVendas, etapaNova: IEtapaProcessoVendas) {
      const venda = state.vendas[indexVenda];
      Modal.confirm({
        title: `Realmente deseja passar a venda da etapa ${etapaAnterior.nome} para a etapa ${etapaNova.nome}?`,
        content: `Venda ${venda.numero} criada no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(venda.dataCriacao)}.`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoVendas.alterarEtapaVenda(venda.codigo, etapaNova.codigo, '');
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            state.vendas[indexVenda].status = etapaNova.statusVenda;
            state.vendas[indexVenda].codigoEtapaProcessoVendas = etapaNova.codigo;
            apresentarMensagemSucesso(`Etapa da venda ${venda.numero} alterada com sucesso!`);
            if (UtilitarioGeral.valorValido(retorno.linkDocumentoFiscal)) {
              state.arquivosPdf = [{ nome: '', link: retorno.linkDocumentoFiscal } as IArquivoPdf];
            }
          } else {
            apresentarMensagemErro(retorno.mensagem);
            preencherCards();
          }
        },
        onCancel: () => {
          preencherCards();
        },
      });
    }

    function abrirCartaCorrecao(codigoVenda: string) {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        state.codigoVendaSelecionada = Number(codigoVenda);
        state.exibirCartaCorrecao = true;
      }
    }

    function abrirUnificacaoNfse(codigoVenda: string) {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        state.codigoVendaSelecionada = Number(codigoVenda);
        state.exibirUnificacaoServicos = true;
      }
    }

    async function moverCard(card: ICardKanban, identificadorNovaColuna: string) {
      if (!processoVendas.value.ativo) {
        apresentarMensagemAlerta('Não é possível mover uma venda com o processo desativado!');
        preencherCards();
        return;
      }

      state.cardSelecionado = card.identificador;
      const indexVenda = state.vendas.findIndex((r) => r.codigo === Number(state.cardSelecionado));
      const venda = state.vendas[indexVenda];

      if (venda.status === EStatusVenda.Cancelada || venda.status === EStatusVenda.Devolvida) {
        apresentarMensagemAlerta('A venda que você tentou mover não pode ser mais modificada.');
        preencherCards();
        return;
      }
      const indexEtapaAnterior = processoVendas.value.etapas.findIndex((e) => e.codigo === venda.codigoEtapaProcessoVendas);
      const indexEtapaNova = processoVendas.value.etapas.findIndex((e) => e.codigo === Number(identificadorNovaColuna));
      const etapaAnterior = processoVendas.value.etapas[indexEtapaAnterior];
      const etapaNova = processoVendas.value.etapas[indexEtapaNova];
      // const codigosProximasEtapas = servicoProcessoVendas.obterCodigosProximasEtapas(venda.codigoEtapaProcessoVendas, processoVendas.value.etapas);
      // const codigosEtapasAnteriores = servicoProcessoVendas.obterCodigosEtapasAnteriores(venda.codigoEtapaProcessoVendas, processoVendas.value.etapas);

      // if (!codigosProximasEtapas.includes(Number(identificadorNovaColuna)) && !codigosEtapasAnteriores.includes(Number(identificadorNovaColuna))) {
      //  apresentarMensagemAlerta(`Não é possível mover a venda da etapa ${etapaAnterior.nome} para a etapa ${etapaNova.nome}`);
      //  preencherCards();
      //  return;
      // }

      if (etapaNova.statusVenda === EStatusVenda.Cancelada) {
        cancelarVenda(venda, etapaNova);
      } else {
        await alterarEtapaVenda(indexVenda, etapaAnterior, etapaNova);
      }
    }

    function cancelarVendaSelecionada(codigoVenda: string) {
      const indexVenda = state.vendas.findIndex((r) => r.codigo === Number(codigoVenda));
      const venda = state.vendas[indexVenda];
      const etapaCancelamento = servicoProcessoVendas.obterEtapaCancelamento(venda.codigoEtapaProcessoVendas.toString(), processoVendas.value.etapas);

      if (etapaCancelamento.codigo > 0) {
        cancelarVenda(venda, etapaCancelamento);
      } else {
        apresentarMensagemAlerta('Não é possível cancelar a venda na etapa atual!');
      }
    }

    async function concluirEtapa() {
      let titulo = '';
      let mensagem = '';
      const vendasSelecionadas = [] as number[];

      state.selecionados.forEach((s) => {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === s);

        if (servicoProcessoVendas.validarPossuiProximasEtapas(state.vendas[indexVenda].codigoEtapaProcessoVendas.toString(), processoVendas.value.etapas)) {
          vendasSelecionadas.push(s);
        }
      });

      if (vendasSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma venda selecionada para concluir a etapa.');
        return;
      } if (vendasSelecionadas.length === 1) {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === vendasSelecionadas[0]);
        titulo = `Realmente deseja concluir a etapa da venda: ${state.vendas[indexVenda].numero}?`;
        mensagem = `Venda ${state.vendas[indexVenda].numero} criada no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(state.vendas[indexVenda].dataCriacao)}.`;
      } else {
        titulo = `Realmente deseja concluir a etapa das ${vendasSelecionadas.length} vendas selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          vendasSelecionadas.forEach(async (codigoVenda) => {
            const retorno = await servicoVendas.concluirEtapa(codigoVenda);
            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
              apresentarMensagemSucesso(retorno.mensagem);
              buscarVendasAposAcoes();
            } else {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          });
        },
      });
    }

    async function retornarEtapa() {
      let titulo = '';
      let mensagem = '';
      const vendasSelecionadas = [] as number[];

      state.selecionados.forEach((s) => {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === s);
        const indexEtapa = processoVendas.value.etapas.findIndex((e) => e.codigo === state.vendas[indexVenda].codigoEtapaProcessoVendas);
        if (servicoProcessoVendas.validarEtapaReferenciada(String(processoVendas.value.etapas[indexEtapa].codigo), processoVendas.value.etapas)) {
          if (processoVendas.value.etapas[indexEtapa].statusVenda !== EStatusVenda.Cancelada) {
            vendasSelecionadas.push(s);
          }
        }
      });

      if (vendasSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma venda selecionada para retornar a etapa.');
        return;
      } if (vendasSelecionadas.length === 1) {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === vendasSelecionadas[0]);
        titulo = `Realmente deseja retornar a etapa da venda: ${state.vendas[indexVenda].numero}?`;
        mensagem = `Venda ${state.vendas[indexVenda].numero} criada no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(state.vendas[indexVenda].dataCriacao)}.`;
      } else {
        titulo = `Realmente deseja retornar a etapa das ${vendasSelecionadas.length} vendas selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          vendasSelecionadas.forEach(async (codigoVenda) => {
            const retorno = await servicoVendas.retornarEtapa(codigoVenda);
            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
              apresentarMensagemSucesso(retorno.mensagem);
              buscarVendasAposAcoes();
            } else {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          });
        },
      });
    }

    async function excluirVendas() {
      let titulo = '';
      let mensagem = '';
      const vendasSelecionadas = [] as number[];

      state.selecionados.forEach((s) => {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === s);
        if (servicoVendas.verificaSePodeExcluir(state.vendas[indexVenda].status)) {
          vendasSelecionadas.push(s);
        }
      });

      if (vendasSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma venda selecionada para exclusão.');
        return;
      } if (vendasSelecionadas.length === 1) {
        const indexVenda = state.vendas.findIndex((v) => v.codigo === vendasSelecionadas[0]);
        titulo = `Realmente deseja excluir a venda ${state.vendas[indexVenda].numero}?`;
        mensagem = `Venda ${state.vendas[indexVenda].numero} criada no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(state.vendas[indexVenda].dataCriacao)}.`;
      } else {
        titulo = `Realmente deseja excluir as ${vendasSelecionadas.length} vendas selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          vendasSelecionadas.forEach(async (codigoVenda) => {
            const retorno = await servicoVendas.excluir(codigoVenda);
            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
              apresentarMensagemSucesso(retorno.mensagem);
              buscarVendasAposAcoes();
            } else {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          });
        },
      });
    }

    async function imprimirDetalhado(codigo: number) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoVendas.imprimirVenda(codigo, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimirListagem(codigos: number[]) {
      const parametrosConsulta = {} as IParametrosConsultaVenda;
      parametrosConsulta.empresas = [dadosEmpresa.value.codigoEmpresa];
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('Status', 'Ordem');

      telaBase.carregando = true;
      const retornoRelatorio = await servicoVendas.relatorioPadrao(processoVendas.value.codigo, dadosEmpresa.value.codigoEmpresa, ETipoArquivo.PDF, parametrosConsulta, state.filtros, codigos);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimir() {
      if (state.selecionados.length === 0) {
        apresentarMensagemAlerta('Nenhuma venda selecionada para impressão!');
      } else if (state.selecionados.length === 1) {
        imprimirDetalhado(state.selecionados[0]);
      } else {
        imprimirListagem(state.selecionados);
      }
    }

    function criarColuna(etapa: IEtapaProcessoVendas) {
      const coluna = {} as IColunaKanban;
      coluna.identificador = String(etapa.codigo);
      coluna.titulo = etapa.nome;
      coluna.cor = etapa.cor;
      coluna.textoTotalizador = 'Total';
      coluna.cards = [] as ICardKanban[];
      state.colunas.push(coluna);
    }

    function preencherColunas() {
      state.colunas = [] as IColunaKanban[];
      processoVendas.value.etapas.forEach((e) => {
        criarColuna(e);
      });
      preencherCards();
    }

    async function obterProcessoVendas() {
      state.carregandoTela = true;
      const codigoProcesso = Number(route.params.codigoProcesso);
      const codigosEtapas = [] as number[];
      processoVendas.value = await servicoProcessoVendas.obterProcessoCompletoCache(codigoProcesso, storeSistema.getters.codigoEmpresaOperacao());
      state.tituloRecurso = processoVendas.value.nome;
      processoVendas.value.etapas.forEach((e) => {
        codigosEtapas.push(e.codigo);
        e.primeiraEtapa = !servicoProcessoVendas.validarEtapaReferenciada(String(e.codigo), processoVendas.value.etapas);
        e.ultimaEtapa = !servicoProcessoVendas.validarPossuiProximasEtapas(String(e.codigo), processoVendas.value.etapas);
      });

      preencherColunas();
      await buscarVendasAposAcoes();
      state.carregandoTela = false;
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarVendas();
      }
    }

    function limparCampos() {
      const dadosEmpresaCache = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === storeSistema.getters.codigoEmpresaOperacao());
      if (dadosEmpresaCache !== undefined) {
        dadosEmpresa.value = dadosEmpresaCache;
      }

      processoVendas.value = {} as IProcessoVendas;
      processoVendas.value.etapas = [] as IEtapaProcessoVendas[];
      state.colunas = [] as IColunaKanban[];
      state.filtros = [] as IFiltroGenerico[];
      state.vendaSelecionada = {} as IDTOVenda;
      state.selecionados = [] as number[];
      state.exibirAlteracaoEtapa = false;
      state.exibirUnificacaoServicos = false;
      state.paginacao = {} as IPagination;
      state.cardSelecionado = '';
      state.vendas = [] as IDTOVenda[];
      state.buscaAvancada = {} as IBuscaAvancada;
      state.exibirLancamento = false;
      state.exibirCancelamento = false;
      state.vendaLancamento = 0;
      state.qtdSelecionados = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.visualizarKanban = !UtilitarioDispositivo.larguraTelaMobile();
    }

    function clickSubMenu(identificador: string) {
      state.cardSelecionado = identificador;
    }

    function verificaVisualizarDFe(codigoVenda: string): boolean {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        const venda = state.vendas.find((c) => c.codigo === Number(codigoVenda));
        if (venda !== undefined) {
          return servicoNotaFiscal.verificaVisualizarDFe(venda.modeloNotaFiscal, venda.identificadorNotaFiscal, venda.statusNotaFiscal);
        }
      }
      return false;
    }

    function verificaVisualizarCancelamento(codigoVenda: string): boolean {
      let verificacao = false;
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        const venda = state.vendas.find((c) => c.codigo === Number(codigoVenda));
        if (venda !== undefined) {
          verificacao = servicoNotaFiscal.verificaVisualizarDFe(venda.modeloNotaFiscal, venda.identificadorNotaFiscal, venda.statusNotaFiscal);
          if (verificacao) {
            const etapaCancelamento = servicoProcessoVendas.obterEtapaCancelamento(venda.codigoEtapaProcessoVendas.toString(), processoVendas.value.etapas);
            verificacao = etapaCancelamento.codigo > 0;
          }
        }
      }
      return verificacao;
    }

    function verificaVisualizarNFSe(codigoVenda: string): boolean {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        const venda = state.vendas.find((c) => c.codigo === Number(codigoVenda));
        if (venda !== undefined) {
          return venda.totalServicos > 0;
        }
      }
      return false;
    }

    function nomeAmigavelModelo(codigoVenda: string): string {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        const venda = state.vendas.find((c) => c.codigo === Number(codigoVenda));
        if (venda !== undefined) {
          return servicoNotaFiscal.nomeAmigavelModelo(venda.modeloNotaFiscal);
        }
      }
      return '';
    }

    async function visualizarDFe(codigoVenda: string) {
      if (UtilitarioGeral.valorValido(codigoVenda)) {
        const venda = state.vendas.find((c) => c.codigo === Number(codigoVenda));
        if (venda !== undefined) {
          const retorno = await servicoNotaFiscal.visualizar(venda.codigoNotaFiscal);
          if (retorno !== undefined) {
            if (retorno.sucesso) {
              if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
                state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
              }
            } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          }
        }
      }
    }

    watch(async () => route.params.codigoProcesso, async () => {
      limparCampos();
      if (Number(route.params.codigoProcesso) > 0) {
        obterProcessoVendas();
      }
    });

    onBeforeMount(async () => {
      limparCampos();
      await preencherEmpresasDisponiveis();
      obterProcessoVendas();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.propriedadesConsulta = await servicoVendas.obterPropriedadesConsulta();
      state.dataAtual = await new ServicoSistema().obterDataAtual();
      permissaoServico.value = await new ServicoSistema().validarPermissaoModulo(storeSistema.getters.codigoEmpresaOperacao(), 8);
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
    });

    return {
      state,
      props,
      telaBase,
      storeSistema,
      dadosEmpresa,
      processoVendas,
      etapaLancamento,
      preencherColunas,
      incluirVenda,
      editarVenda,
      clickEditar,
      excluirVendas,
      retornarEtapa,
      buscarVendas,
      EFiltroPeriodoMovimentoComercial,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      selecionarCard,
      periodoAlterado,
      concluirEtapa,
      moverCard,
      atualizarSelecionados,
      atualizarOrdemCards,
      atualizarTodosSelecionados,
      imprimirDetalhado,
      imprimir,
      clickSubMenu,
      verificaVisualizarDFe,
      nomeAmigavelModelo,
      visualizarDFe,
      abrirCartaCorrecao,
      abrirUnificacaoNfse,
      verificaVisualizarNFSe,
      cancelarVendaSelecionada,
      EStatusVenda,
      verificaVisualizarCancelamento,
    };
  },
});
