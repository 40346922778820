import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { key: 6 }
const _hoisted_13 = { key: 7 }
const _hoisted_14 = { key: 8 }
const _hoisted_15 = { key: 9 }
const _hoisted_16 = {
  key: 10,
  class: "ss-table-apresentacao-mobile"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  class: "acoes-item",
  style: {"margin-top":"2px"}
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "ant-divider ant-divider-horizontal",
  role: "separator",
  style: {"margin-top":"10px","margin-bottom":"0px"}
}
const _hoisted_22 = { class: "ant-row" }
const _hoisted_23 = {
  key: 0,
  class: "ant-col",
  style: {"flex":"auto 1 1"}
}
const _hoisted_24 = { class: "ant-row" }
const _hoisted_25 = {
  key: 1,
  class: "ant-col ant-col-xs-24",
  style: {"margin-top":"5px"}
}
const _hoisted_26 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_27 = ["value"]
const _hoisted_28 = {
  key: 2,
  class: "ant-col ant-col-xs-24 ant-col-xl-3"
}
const _hoisted_29 = {
  key: 3,
  class: "ant-col ant-col-xs-24 ant-col-xl-3"
}
const _hoisted_30 = {
  key: 4,
  class: "ant-col ant-col-xs-24 ant-col-xl-3"
}
const _hoisted_31 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_venda_detalhe_pagamento_modal = _resolveComponent("venda-detalhe-pagamento-modal")!

  return (_ctx.props.venda.codigoFormaPagamento === undefined || _ctx.props.venda.codigoFormaPagamento === 0)
    ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
        key: 0,
        mensagem: "Selecione uma forma de pagamento para começar"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_table, {
              columns: _ctx.state.colunasGrade.filter(item => item.visible),
              "data-source": _ctx.computedDetalhesPagamento,
              rowKey: "numeroTitulo",
              pagination: false,
              scroll: { y: 200},
              size: "small",
              rowClassName: "ss-table-campos"
            }, {
              emptyText: _withCtx(() => [
                _createVNode(_component_mensagem_sem_dados, { mensagem: "Nenhum item adicionado ainda" })
              ]),
              bodyCell: _withCtx(({ column, record, index }) => [
                (column.key === 'acoes')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("a", {
                        class: "visualizar",
                        onClick: ($event: any) => (_ctx.editarDetalhe(index))
                      }, [
                        _createVNode(_component_icone, { nome: "editar" })
                      ], 8, _hoisted_5),
                      (_ctx.props.editavel)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "excluir",
                            onClick: ($event: any) => (_ctx.excluirDetalhe(index))
                          }, [
                            _createVNode(_component_icone, { nome: "excluir" })
                          ], 8, _hoisted_6))
                        : _createCommentVNode("", true)
                    ]))
                  : (column.key === 'NumeroTitulo')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(record.numeroTitulo), 1))
                    : (column.key === 'TipoDocumentoFinanceiro')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(record.descricaoTipoDocumentoFinanceiro), 1))
                      : (column.key === 'Vencimento')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.UtilitarioData.aplicaFormatoData(record.data)), 1))
                        : (column.key === 'ValorPagamento')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.valorPagamento, 2, true)), 1))
                          : (column.key === 'Acrescimos')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.acrescimos, 2, true)), 1))
                            : (column.key === 'OutrasTaxas')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.outrasTaxas, 2, true)), 1))
                              : (column.key === 'ValorTotal')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.valorTotal, 2, true)), 1))
                                : (column.key === 'Conta')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(record.descricaoConta), 1))
                                  : (column.key === 'Categoria')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(record.nomePlanoContaCategoria), 1))
                                    : (column.key === 'DetalhePagamento')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          _createElementVNode("span", null, [
                                            _createElementVNode("a", {
                                              onClick: ($event: any) => (_ctx.editarDetalhe(index))
                                            }, [
                                              _createElementVNode("span", null, "Título: " + _toDisplayString(record.numeroTitulo), 1)
                                            ], 8, _hoisted_17)
                                          ]),
                                          _createElementVNode("span", null, "Conta: " + _toDisplayString(record.descricaoConta), 1),
                                          _createElementVNode("span", null, "Categoria " + _toDisplayString(record.nomePlanoContaCategoria), 1),
                                          _createElementVNode("span", null, "Vencimento: " + _toDisplayString(_ctx.UtilitarioData.aplicaFormatoData(record.data)), 1),
                                          _createElementVNode("span", null, "Valor: " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.valorTotal, 2, true)), 1),
                                          _createElementVNode("div", _hoisted_18, [
                                            _createElementVNode("a", {
                                              class: "visualizar",
                                              onClick: ($event: any) => (_ctx.editarDetalhe(index))
                                            }, [
                                              _createVNode(_component_icone, { nome: "editar" })
                                            ], 8, _hoisted_19),
                                            (_ctx.props.editavel)
                                              ? (_openBlock(), _createElementBlock("a", {
                                                  key: 0,
                                                  class: "excluir",
                                                  onClick: ($event: any) => (_ctx.excluirDetalhe(index))
                                                }, [
                                                  _createVNode(_component_icone, { nome: "excluir" })
                                                ], 8, _hoisted_20))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["columns", "data-source"])
          ])
        ]),
        (!_ctx.state.telaMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_22, [
          (!_ctx.state.telaMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_a_form_item, {
                    label: " ",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.adicionarDetalhe && _ctx.adicionarDetalhe(...args)))
                      }, "Inserir pagamento")
                    ]),
                    _: 1
                  })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createElementVNode("button", {
                  class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                  style: {"width":"100%"},
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.adicionarDetalhe && _ctx.adicionarDetalhe(...args)))
                }, "Inserir pagamento")
              ])),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_a_form_item, {
              label: "Quantidade",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  style: {"text-align":"right"},
                  value: _ctx.venda.notaFiscal.detalhesPagamento.length,
                  disabled: "true",
                  onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.atualizarTotalizadores && _ctx.atualizarTotalizadores(...args)))
                }, null, 40, _hoisted_27)
              ]),
              _: 1
            })
          ]),
          (_ctx.computedTotalCaixa > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createVNode(_component_a_form_item, {
                  label: "Total de Caixa",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedTotalCaixa,
                      disabled: true
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.computedTotalCarteira > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createVNode(_component_a_form_item, {
                  label: "Total de Carteira",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedTotalCarteira,
                      disabled: true
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.computedTotalOutros > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createVNode(_component_a_form_item, {
                  label: "Total Outros",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedTotalOutros,
                      disabled: true
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_a_form_item, {
              label: "Total Lançado",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  quantidadeCasasDecimais: 2,
                  valor: _ctx.computedTotalCaixa + _ctx.computedTotalCarteira + _ctx.computedTotalOutros,
                  disabled: true
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_venda_detalhe_pagamento_modal, {
          venda: _ctx.props.venda,
          editavel: _ctx.props.editavel,
          visivel: _ctx.state.exibirLancamento,
          "onUpdate:visivel": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.exibirLancamento) = $event)),
          detalhePagamento: _ctx.state.detalheSelecionado,
          "onUpdate:detalhePagamento": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.detalheSelecionado) = $event)),
          onConfirmar: _ctx.adicionarTaxas
        }, null, 8, ["venda", "editavel", "visivel", "detalhePagamento", "onConfirmar"])
      ]))
}