
import {
  defineComponent, reactive, watch, inject, Ref,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarProdutoMovimento from '@/components/Cadastros/Produtos/SelecionarProdutoMovimento.vue';
import SelecionarUnidadeProdutoMovimento from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidadeProdutoMovimento.vue';
import SelecionarItemTabelaPrecoProduto from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarItemTabelaPrecoProduto.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCenarioFiscal from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCenarioFiscal.vue';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import NotaFiscalItemDetalhe from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDetalhe.vue';
import NotaFiscalItemImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemImpostos.vue';
import NotaFiscalItemEstoques from '@/components/Fiscal/NotasFiscais/NotaFiscalItemEstoques.vue';
import NotaFiscalItemDadosEspecificos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDadosEspecificos.vue';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';
import {
  INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalItemCombustivel, INotaFiscalItemEstoque, INotaFiscalItemImposto, INotaFiscalItemImpostoCofins, INotaFiscalItemImpostoIcms, INotaFiscalItemImpostoImportacao, INotaFiscalItemImpostoIpi, INotaFiscalItemImpostoPis, INotaFiscalItemImpostoSubstituicaoTributaria, INotaFiscalItemMedicamento,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import { IDTOTabelaPrecoItemProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPrecoItemProduto';
import { IVendaItem } from '@/models/Entidades/Vendas/IVenda';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import { IParametrosConsultaDefinicaoMovimentacao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicaoMovimentacao';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import storeSistema from '@/store/storeSistema';
import { ETipoOperacaoCfop } from '@/models/Enumeradores/Cadastros/Tributacoes/ETipoOperacaoCfop';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import { useFocusBase } from '@/core/composables/FocusBase';

export default defineComponent({
  name: 'VendaItemModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    dadosPessoaMovimento: {
      type: Object as () => IDTOPessoaMovimento,
      required: true,
    },
    tabelasPrecos: {
      type: Array as () => number[],
      required: true,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    atualizarValores: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object as () => IVendaItem,
      required: true,
    },
    totalNota: {
      type: Number,
      required: true,
    },
    existeProximo: {
      type: Boolean,
      required: true,
    },
    existeAnterior: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarProdutoMovimento,
    SelecionarUnidadeProdutoMovimento,
    SelecionarItemTabelaPrecoProduto,
    SelecionarSimNao,
    CampoNumerico,
    SelecionarCenarioFiscal,
    SelecionarCfop,
    NotaFiscalItemDetalhe,
    NotaFiscalItemImpostos,
    NotaFiscalItemEstoques,
    NotaFiscalItemDadosEspecificos,
  },
  emits: ['update:visivel', 'salvar', 'proximoItem', 'itemAnterior'],
  setup(props, { emit }) {
    const servicoEstoque = new ServicoEstoque();
    servicoEstoque.requisicaoSistema();

    const servicoCfop = new ServicoCfop();
    servicoCfop.requisicaoSistema();

    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    servicoCenarioFiscal.requisicaoSistema();
    const servicoVendas = new ServicoVendas();

    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior,
    } = useFocusBase();

    const dadosEmpresa = inject('VENDAS_EMPRESA') as Ref<IDTODadosEmpresa>;
    const incidenciasCalculo = inject('VENDA_INCIDENCIA_CALCULO') as INotaFiscalIncidenciaCalculo[];
    const cfopSugerido = inject('VENDAS_CFOP_SUGERIDO') as Ref<ICfop>;

    const state = reactive({
      item: {} as IVendaItem,
      tabelasPreco: [] as number[],
      estoquesDisponiveis: [] as number[],
      qtdCasasDecimaisCusto: 2,
      totalNota: 0,
      telaMobile: false,
      apresentarTabelasPrecos: false,
      carregando: false,
      tipoOperacaoCfop: {} as ETipoOperacaoCfop,
    });

    function instanciaNovoItem():IVendaItem {
      const itemVenda = {} as IVendaItem;
      itemVenda.emDigitacao = false;
      itemVenda.dadosPrecoProduto = {} as IDTOTabelaPrecoItemProduto;
      itemVenda.dadosPrecoProduto.codigo = 0;
      itemVenda.dadosPrecoProduto.casasDecimaisTipoPreco = 2;
      itemVenda.percentualFormacaoPreco = 0;
      itemVenda.itemNotaFiscal = {} as INotaFiscalItem;
      itemVenda.itemNotaFiscal.estoquesDisponiveis = [] as IEstoque[];
      itemVenda.itemNotaFiscal.codigoProdutoDefinicao = 0;
      itemVenda.itemNotaFiscal.codigoUnidadePrincipal = 0;
      itemVenda.itemNotaFiscal.fatorUnidadePrincipal = 1;
      itemVenda.itemNotaFiscal.codigoUnidade = 0;
      itemVenda.itemNotaFiscal.fracionarUnidade = false;
      itemVenda.itemNotaFiscal.siglaUnidade = '';
      itemVenda.itemNotaFiscal.fatorConversao = 1;
      itemVenda.itemNotaFiscal.casasDecimaisUnidade = 2;
      itemVenda.itemNotaFiscal.precoUnitario = 0;
      itemVenda.itemNotaFiscal.casasDecimaisPrecoUnitario = 2;
      itemVenda.itemNotaFiscal.valorDesconto = 0;
      itemVenda.itemNotaFiscal.valorTotal = 0;
      itemVenda.itemNotaFiscal.movimentarEstoque = true;
      itemVenda.itemNotaFiscal.compoeTotal = true;
      itemVenda.itemNotaFiscal.origemFrete = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.valorFrete = 0;
      itemVenda.itemNotaFiscal.origemOutrasDespesas = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.origemSeguro = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.valorSeguro = 0;
      itemVenda.itemNotaFiscal.impostos = {} as INotaFiscalItemImposto;
      itemVenda.itemNotaFiscal.impostos.codigoCfop = 1;
      itemVenda.itemNotaFiscal.impostos.icms = {} as INotaFiscalItemImpostoIcms;
      itemVenda.itemNotaFiscal.impostos.cst = '00';
      itemVenda.itemNotaFiscal.impostos.origemMercadoria = 0;
      itemVenda.itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = 3;
      itemVenda.itemNotaFiscal.impostos.icms.valorIcms = 0;
      itemVenda.itemNotaFiscal.impostos.ipi = {} as INotaFiscalItemImpostoIpi;
      itemVenda.itemNotaFiscal.impostos.ipi.cstIpi = '52';
      itemVenda.itemNotaFiscal.impostos.ipi.tipoCalculoIpi = 1;
      itemVenda.itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = '999';
      itemVenda.itemNotaFiscal.impostos.ipi.valorIpi = 0;
      itemVenda.itemNotaFiscal.impostos.pis = {} as INotaFiscalItemImpostoPis;
      itemVenda.itemNotaFiscal.impostos.pis.cstPis = '07';
      itemVenda.itemNotaFiscal.impostos.pis.tipoCalculoPis = 1;
      itemVenda.itemNotaFiscal.impostos.pis.valorPis = 0;
      itemVenda.itemNotaFiscal.impostos.cofins = {} as INotaFiscalItemImpostoCofins;
      itemVenda.itemNotaFiscal.impostos.cofins.cstCofins = '07';
      itemVenda.itemNotaFiscal.impostos.cofins.tipoCalculoCofins = 1;
      itemVenda.itemNotaFiscal.impostos.cofins.valorCofins = 0;
      itemVenda.itemNotaFiscal.impostos.st = {} as INotaFiscalItemImpostoSubstituicaoTributaria;
      itemVenda.itemNotaFiscal.impostos.importacao = {} as INotaFiscalItemImpostoImportacao;
      itemVenda.itemNotaFiscal.impostos.importacao.valorImportacao = 0;
      itemVenda.itemNotaFiscal.impostos.importacao.valorIof = 0;
      itemVenda.itemNotaFiscal.impostos.importacao.despesasAduaneiras = 0;
      itemVenda.itemNotaFiscal.medicamento = {} as INotaFiscalItemMedicamento;
      itemVenda.itemNotaFiscal.combustivel = {} as INotaFiscalItemCombustivel;
      itemVenda.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
      return itemVenda;
    }

    function limparCampos() {
      state.item = instanciaNovoItem();
      state.tabelasPreco = [] as number[];
      state.estoquesDisponiveis = [] as number[];
      state.qtdCasasDecimaisCusto = 2;
      state.totalNota = 0;
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    }

    function calcularTotalNota() {
      state.totalNota = props.totalNota;
      if (props.item.itemNotaFiscal.compoeTotal && state.item.codigo !== undefined) {
        state.totalNota -= props.item.itemNotaFiscal.valorTotal;
      }

      if (state.item.itemNotaFiscal.compoeTotal) {
        state.totalNota += state.item.itemNotaFiscal.valorTotal;
      }
    }

    function atualizarAlteracaoItem() {
      if (props.editavel) {
        state.item.alterado = true;
        state.item = servicoVendas.calcularTotalItem(state.item);
        calcularTotalNota();
      }
    }

    function validarImpostos() {
      if (!UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.cst) && !UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.cson)) {
        apresentarMensagemAlerta('O CST/CSOSN deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.pis.cstPis)) {
        apresentarMensagemAlerta('O CST do PIS deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.item.itemNotaFiscal.impostos.cofins.cstCofins)) {
        apresentarMensagemAlerta('O CST do COFINS deve ser informado!');
        return false;
      }
      return true;
    }

    function validarEstoque() {
      if (!state.item.itemNotaFiscal.movimentarEstoque) return true;

      // if (!UtilitarioGeral.validaLista(state.item.itemNotaFiscal.estoques)) {
      // apresentarMensagemAlerta('Pelo menos um item de estoque deve ser informado!');
      // return false;
      // }
      let estoqueSemPreencher = false;

      let qtdTotal = 0;
      state.item.itemNotaFiscal.estoques.forEach((e) => {
        qtdTotal += e.quantidade;
        if (!UtilitarioGeral.validaCodigo(e.codigoEstoque)) {
          estoqueSemPreencher = true;
        }
      });

      if (estoqueSemPreencher) {
        apresentarMensagemAlerta('É necessário selecionar o estoque do produto.');
        return false;
      }

      // if (state.item.itemNotaFiscal.quantidade !== qtdTotal) {
      //  apresentarMensagemAlerta('A quantidade total dos estoques está diferente da quantidade do item!');
      // return false;
      // }
      return true;
    }

    function validarPreco() {
      return true;
    }

    function validarCampos() {
      if (state.item.itemNotaFiscal.codigoProdutoDefinicao === undefined || state.item.itemNotaFiscal.codigoProdutoDefinicao === 0) {
        apresentarMensagemAlerta('O Produto deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.codigoUnidade === undefined || state.item.itemNotaFiscal.codigoUnidade === 0) {
        apresentarMensagemAlerta('A Unidade deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.quantidade === undefined || state.item.itemNotaFiscal.quantidade < 1) {
        apresentarMensagemAlerta('A Quantidade deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.valorTotal === undefined || state.item.itemNotaFiscal.valorTotal < 1) {
        apresentarMensagemAlerta('O Total do Item deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.codigoCfop === undefined || state.item.itemNotaFiscal.impostos.codigoCfop === 0) {
        apresentarMensagemAlerta('O CFOP deve ser informado!');
        return false;
      }

      if (!validarImpostos()) {
        return false;
      }

      if (!validarEstoque()) {
        return false;
      }

      if (!validarPreco()) {
        return false;
      }

      return true;
    }

    function calculaTotalItemEAtualizaTotalNota() {
      state.item.itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(state.item.itemNotaFiscal.quantidade, state.item.itemNotaFiscal.precoUnitario, state.item.itemNotaFiscal.valorDesconto, 0);
      calcularTotalNota();
    }

    function atualizarQtdEstoque() {
      if (state.item.itemNotaFiscal.estoques.length === 1) {
        state.item.itemNotaFiscal.estoques[0].quantidade = state.item.itemNotaFiscal.quantidade;
      }
      calculaTotalItemEAtualizaTotalNota();
    }

    function limparEstoques() {
      state.item.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
    }

    function salvar() {
      state.item.itemNotaFiscal.estoques.forEach((estoque, index) => {
        if (state.item.itemNotaFiscal.estoques[index].itemNovo) {
          state.item.itemNotaFiscal.estoques[index].codigoEstoqueItem = 0;
        }
      });

      if (validarCampos()) {
        if (state.item.codigo === undefined) {
          state.item.codigo = 0;
        } else {
          state.item.alterado = true;
        }
        emit('salvar', state.item);
      }
    }

    async function proximoItem() {
      emit('proximoItem');
    }

    async function itemAnterior() {
      emit('itemAnterior');
    }

    async function obterDadosFiscaisProduto() {
      if (UtilitarioGeral.validaCodigo(state.item.itemNotaFiscal.codigoProdutoDefinicao)) {
        const parametrosConsultaDefinicaoMovimentacao: IParametrosConsultaDefinicaoMovimentacao = {} as IParametrosConsultaDefinicaoMovimentacao;
        parametrosConsultaDefinicaoMovimentacao.tipoMovimentoComercial = ETipoMovimentoComercial.Vendas;
        parametrosConsultaDefinicaoMovimentacao.codigoEmpresa = props.empresa;
        parametrosConsultaDefinicaoMovimentacao.codigoEstado = props.dadosPessoaMovimento.codigoEstado;
        parametrosConsultaDefinicaoMovimentacao.tipoAtividade = props.dadosPessoaMovimento.tipoAtividade;
        parametrosConsultaDefinicaoMovimentacao.tipoIe = props.dadosPessoaMovimento.tipoIe;
        parametrosConsultaDefinicaoMovimentacao.pessoaSuperSimples = props.dadosPessoaMovimento.simplesNacional;
        parametrosConsultaDefinicaoMovimentacao.codigoProdutoDefinicao = state.item.itemNotaFiscal.codigoProdutoDefinicao;
        parametrosConsultaDefinicaoMovimentacao.codigoNcm = state.item.itemNotaFiscal.impostos.codigoNcm;
        parametrosConsultaDefinicaoMovimentacao.origemMercadoria = state.item.itemNotaFiscal.impostos.origemMercadoria;

        const definicoesTributarias = await servicoCenarioFiscal.obterDefinicoesMovimentacao(parametrosConsultaDefinicaoMovimentacao);
        if (definicoesTributarias.sucesso) {
          if (definicoesTributarias.encontrouDefinicoesIcms) {
            state.item.itemNotaFiscal.impostos.codigoCfop = definicoesTributarias.icms.codigoCfop;
            state.item.itemNotaFiscal.impostos.cfop = definicoesTributarias.icms.cfop;
            if (UtilitarioGeral.valorValido(definicoesTributarias.icms.cstIcms)) {
              state.item.itemNotaFiscal.impostos.cst = definicoesTributarias.icms.cstIcms;
              state.item.itemNotaFiscal.impostos.cson = '';
            } else {
              state.item.itemNotaFiscal.impostos.cson = definicoesTributarias.icms.cson;
              state.item.itemNotaFiscal.impostos.cst = '';
            }
            state.item.itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = definicoesTributarias.icms.modalidadeBaseCalculoIcms;
            state.item.itemNotaFiscal.impostos.icms.aliquotaIcms = definicoesTributarias.icms.aliquotaIcms;
            state.item.itemNotaFiscal.impostos.icms.reducaoBaseCalculoIcms = definicoesTributarias.icms.reducaoBaseCalculoIcms;
            state.item.itemNotaFiscal.impostos.icms.aliquotaFcp = definicoesTributarias.icms.aliquotaFcp;
            state.item.itemNotaFiscal.impostos.icms.calcularIcmsInterestadual = definicoesTributarias.icms.calcularIcmsInterestadual;
          }

          if (definicoesTributarias.encontrouDefinicoesSubstituicaoTributaria) {
            state.item.itemNotaFiscal.impostos.st.modalidadeBaseCalculoSubstituicao = definicoesTributarias.icms.substituicao.modalidadeBaseCalculoIcmsSubstituicao;
            state.item.itemNotaFiscal.impostos.st.aliquotaSubstituicaoTributaria = definicoesTributarias.icms.substituicao.aliquotaIcmsSubstituicao;
            state.item.itemNotaFiscal.impostos.st.aliquotaIcmsInterno = definicoesTributarias.icms.substituicao.aliquotaIcmsInterno;
            state.item.itemNotaFiscal.impostos.st.margemValorAdicionado = definicoesTributarias.icms.substituicao.margemValorAdicionado;
            state.item.itemNotaFiscal.impostos.st.pautaIcmsSubstituicao = definicoesTributarias.icms.substituicao.pautaIcmsSubstituicao;
            state.item.itemNotaFiscal.impostos.st.reducaoBaseCalculoSubstituicao = definicoesTributarias.icms.substituicao.reducaoBaseCalculoIcmsSubstituicao;
          }

          if (definicoesTributarias.encontrouDefinicoesIpi) {
            state.item.itemNotaFiscal.impostos.ipi.cstIpi = definicoesTributarias.ipi.cstIpi;
            state.item.itemNotaFiscal.impostos.ipi.tipoCalculoIpi = definicoesTributarias.ipi.tipoCalculoIpi;
            state.item.itemNotaFiscal.impostos.ipi.aliquotaIpi = definicoesTributarias.ipi.aliquotaIpi;
            state.item.itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = definicoesTributarias.ipi.codigoEnquadramentoIpi;
          }

          if (definicoesTributarias.encontrouDefinicoesPis) {
            state.item.itemNotaFiscal.impostos.pis.cstPis = definicoesTributarias.pis.cstPis;
            state.item.itemNotaFiscal.impostos.pis.tipoCalculoPis = definicoesTributarias.pis.tipoCalculoPis;
            state.item.itemNotaFiscal.impostos.pis.aliquotaPis = definicoesTributarias.pis.aliquotaPis;
            state.item.itemNotaFiscal.impostos.pis.reducaoBaseCalculoPis = definicoesTributarias.pis.reducaoBaseCalculoPis;
          }

          if (definicoesTributarias.encontrouDefinicoesCofins) {
            state.item.itemNotaFiscal.impostos.cofins.cstCofins = definicoesTributarias.cofins.cstCofins;
            state.item.itemNotaFiscal.impostos.cofins.tipoCalculoCofins = definicoesTributarias.cofins.tipoCalculoCofins;
            state.item.itemNotaFiscal.impostos.cofins.aliquotaCofins = definicoesTributarias.cofins.aliquotaCofins;
            state.item.itemNotaFiscal.impostos.cofins.reducaoBaseCalculoCofins = definicoesTributarias.cofins.reducaoBaseCalculoCofins;
          }
        }

        if (!UtilitarioGeral.validaCodigo(state.item.itemNotaFiscal.impostos.codigoCfop) && UtilitarioGeral.objetoValido(cfopSugerido.value)) {
          state.item.itemNotaFiscal.impostos.codigoCfop = cfopSugerido.value.codigo;
          state.item.itemNotaFiscal.impostos.cfop = cfopSugerido.value.codigoCfop;
        }
      }
    }

    async function calcularTributacoes() {
      state.item.itemNotaFiscal.impostos.icms.aliquotaSimplesNacional = dadosEmpresa.value.aliquotaSimplesNacional;
      const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.item.itemNotaFiscal, incidenciasCalculo, dadosEmpresa.value.uf);
      if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
        state.item.itemNotaFiscal.impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
        state.item.itemNotaFiscal.impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;
        state.item.itemNotaFiscal.impostos.icms.valorSimplesNacional = retornoCalculoTributacoes.icms.valorSimplesNacional;

        state.item.itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
        state.item.itemNotaFiscal.impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

        state.item.itemNotaFiscal.impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
        state.item.itemNotaFiscal.impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

        state.item.itemNotaFiscal.impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
        state.item.itemNotaFiscal.impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

        state.item.itemNotaFiscal.impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
        state.item.itemNotaFiscal.impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;

        state.item.itemNotaFiscal.impostos.valorTotalTributos = retornoCalculoTributacoes.valorTotalTributos;
      }
    }

    function defineValorUnitarioCalculaTotal(precoUnitario: number) {
      if (props.editavel) {
        state.item.itemNotaFiscal.precoUnitario = precoUnitario;
        state.item = servicoVendas.calcularTotalItem(state.item);
        calcularTotalNota();
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparCampos();
      if (modalBase.computedVisivel) {
        const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === props.empresa);
        if (dadosEmpresa !== undefined) {
          if (props.dadosPessoaMovimento.uf === dadosEmpresa.uf) {
            state.tipoOperacaoCfop = ETipoOperacaoCfop.SaidaDentroEstado;
          } else if (props.dadosPessoaMovimento.uf === 'EX') {
            state.tipoOperacaoCfop = ETipoOperacaoCfop.SaidaExportacao;
          } else {
            state.tipoOperacaoCfop = ETipoOperacaoCfop.SaidaForaEstado;
          }
        }
        state.item = UtilitarioGeral.clonarObjeto(props.item);
      }
      telaBase.carregando = false;
    });

    watch(async () => props.item, async () => {
      state.item = props.item;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      storeSistema,
      UtilitarioGeral,
      UtilitarioMascara,
      limparEstoques,
      salvar,
      proximoItem,
      itemAnterior,
      calcularTotalNota,
      atualizarQtdEstoque,
      calculaTotalItemEAtualizaTotalNota,
      calcularTributacoes,
      obterDadosFiscaisProduto,
      atualizarAlteracaoItem,
      defineValorUnitarioCalculaTotal,
      focusElemento,
      proximoElemento,
      elementoAnterior,
    };
  },
});
